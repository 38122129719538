* {
  font-family: Roboto, sans-serif;
}

html,
body,
#root {
  min-width: 340px;
}

:focus-visible {
  outline: none;
}

// Remove the Edge password reveal button
// https://learn.microsoft.com/en-us/microsoft-edge/web-platform/password-reveal
::-ms-reveal {
  display: none;
}
